<template>
  <div class="content">
    <modal :show="isModalShow" :showClose="false">
      <template slot="header">
        <h5 class="modal-title">{{ $t("confirm.delete.category") }}</h5>
      </template>
      <template slot="footer">
        <base-button type="secondary" @click="isModalShow = false">{{
          $t("cancel")
        }}</base-button>
        <base-button type="danger" @click="deleteCategory">{{
          $t("delete")
        }}</base-button>
      </template>
    </modal>
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="d-block col d-sm-flex justify-content-between mb-3" v-if="isAdmin">
              <div>
                <base-button
                  type="cancel"
                  @click="goBack()"
                >
                  {{ $t("goBack") }}
                </base-button>
              </div>
              <div>
                <base-button
                  v-if="id !== null" 
                  type="danger"
                  form="categoryForm"
                  class="mr-4"
                  @click="showDeleteModal"
                >
                  {{ $t("delete") }}
                </base-button>

                <base-button
                  :disabled="isBtnDisabled"
                  native-type="submit"
                  type="success"
                  form="categoryForm"
                >
                  {{ $t("save") }}
                </base-button>
              </div>
            </div>
            <div class="col d-flex justify-content-between">
              <div>
                <h1 v-if="id == null && isAdmin" class="card-title mb-0">
                  {{ $t(`routes.${this.$route.name}`) }}
                </h1>
                <h1 v-if="id != null && isAdmin" class="mb-0">
                  {{ category.code }}
                </h1>
              </div>
            </div>
          </template>
          <form id="categoryForm" @change="disabledBtn(false)" @submit.prevent="manageItem()">
            <hr />
            <div v-if="!isSpinnerShow" class="col row py-2">
              <div class="col-12 col-md-6">
                <base-input
                  max="255"
                  v-model="category.code"
                  :placeholder="$t('name')"
                  :label="`${$t('name')}*`"
                  required
                ></base-input>
              </div>
            </div>
            <Spinner v-else></Spinner>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseButton, BaseInput, Card, Spinner, Modal } from "@/components/index";
import { mapActions, mapState } from "vuex";

export default {
  name: "CategoryForm",
  data: () => {
    return {
      id: null,
      route: "/store-categories",
      isBtnDisabled: true,
      category: {
        code: null,
      },
      isSpinnerShow: true,
      isModalShow: false
    };
  },
  computed: {
    ...mapState(["isAdmin"]),
  },
  methods: {
    createItem() {
      return {
        code: this.category.code,
      };
    },
    manageItem() {
      const category = this.createItem();
      if (this.id != null && this.isAdmin) {
        this.updateItem(category);
        return;
      }
      this.storeItem(category);
    },
    async storeItem(category) {
      this.disabledBtn(true);
      const response = await this.axios.post(this.route, category);
      if (response && response.data.status === "success") {
        this.$router.push({ name: "categoryList" });
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    async getItem() {
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        this.category = response.data.data.store_categories;
        this.disabledBtn(true);
        this.isSpinnerShow = false;
      }
    },
    async updateItem(category) {
      this.disabledBtn(true);
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.put(route, category);
      if (response && response.data.status === "success") {
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    async deleteCategory() {
      try {
        const response = await this.axios.delete(`${this.route}/${this.id}`);
        this.isModalShow = false;
        if (response && response.data.status === "success") {
          this.$router.push({ name: "categoryList" });
          this.$toast.success(this.$t("notifications.success.general"));
        } else {
          this.$toast.error(this.$t("notifications.error.noDeleteCategoryInUse"));
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(this.$t("notifications.error.general"));
      }
    },
    showDeleteModal(){
      this.isModalShow = true;
    },
    disabledBtn(disabled) {
      this.$store.dispatch('setHasChangeForm', !disabled );
      this.isBtnDisabled = disabled;
    },
    goBack(){
      this.$router.push({ name: 'categoryList' }).catch(()=>{
        console.warn('the redirection was stopped') 
      })
    },
    ...mapActions(["setHasChangeForm"])
  },
  beforeMount() {
    this.id = this.$route.params.id || null;
    if (this.id != null) {
      this.getItem();
    } else {
      this.isSpinnerShow = false;
    }
  },
  components: {
    Card,
    BaseButton,
    BaseInput,
    Spinner,
    Modal
  },
};
</script>
<style scoped>
.img-size {
  width: 250px;
  height: 250px;
  object-fit: cover;
}
</style>
